define("discourse/plugins/chat/discourse/components/chat/navbar/title", ["exports", "@ember/helper", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/components/chat/navbar/sub-title", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _helper, _dIcon, _subTitle, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatNavbarTitle = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div title={{@title}} class="c-navbar__title">
      {{#if (has-block)}}
        {{if @icon (icon @icon)}}
        {{@title}}
        {{yield (hash SubTitle=SubTitle)}}
      {{else}}
        {{if @icon (icon @icon)}}
        {{@title}}
      {{/if}}
    </div>
  
  */
  {
    "id": "ynrh3R7J",
    "block": "[[[1,\"\\n  \"],[10,0],[15,\"title\",[30,1]],[14,0,\"c-navbar__title\"],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[1,[52,[30,2],[28,[32,0],[[30,2]],null]]],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n      \"],[18,3,[[28,[32,1],null,[[\"SubTitle\"],[[32,2]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[52,[30,2],[28,[32,0],[[30,2]],null]]],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[\"@title\",\"@icon\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/title.js",
    "scope": () => [_dIcon.default, _helper.hash, _subTitle.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatNavbarTitle;
});