define("discourse/plugins/chat/discourse/components/chat/routes/channel-thread", ["exports", "@ember/helper", "discourse/plugins/chat/discourse/components/chat/thread/header", "discourse/plugins/chat/discourse/components/chat-thread", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _helper, _header, _chatThread, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatRoutesChannelThread = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="c-routes-channel-thread">
      {{#each (array @thread) as |thread|}}
        <ThreadHeader @thread={{thread}} />
  
        <Thread
          @thread={{thread}}
          @targetMessageId={{@targetMessageId}}
          @includeHeader={{true}}
        />
      {{/each}}
    </div>
  
  */
  {
    "id": "zaZ+AMtd",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"c-routes-channel-thread\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[28,[32,0],[[30,1]],null]],null]],null],null,[[[1,\"      \"],[8,[32,1],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n\\n      \"],[8,[32,2],null,[[\"@thread\",\"@targetMessageId\",\"@includeHeader\"],[[30,2],[30,3],true]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@thread\",\"thread\",\"@targetMessageId\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-thread.js",
    "scope": () => [_helper.array, _header.default, _chatThread.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatRoutesChannelThread;
});