define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-thread", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-thread", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _object, _didInsert, _didUpdate, _service, _ajaxError, _discourseI18n, _navbar, _chatThread, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatDrawerRoutesChannelThread = _exports.default = (_class = (_class2 = class ChatDrawerRoutesChannelThread extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor2, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor3, this);
      _initializerDefineProperty(this, "chatHistory", _descriptor4, this);
    }
    get backButton() {
      const link1 = {
        models: this.chat.activeChannel?.routeModels
      };
      if (this.chatHistory.previousRoute?.name === "chat.channel.threads") {
        link1.title = _discourseI18n.default.t("chat.return_to_threads_list");
        link1.route = "chat.channel.threads";
      } else if (this.chatHistory.previousRoute?.name === "chat.threads") {
        link1.title = _discourseI18n.default.t("chat.my_threads.title");
        link1.route = "chat.threads";
        link1.models = [];
      } else {
        link1.title = _discourseI18n.default.t("chat.return_to_channel");
        link1.route = "chat.channel";
      }
      return link1;
    }
    get threadTitle() {
      return this.chat.activeChannel?.activeThread?.title ?? _discourseI18n.default.t("chat.thread.label");
    }
    async fetchChannelAndThread() {
      if (!this.args.params?.channelId || !this.args.params?.threadId) {
        return;
      }
      try {
        const channel1 = await this.chatChannelsManager.find(this.args.params.channelId);
        this.chat.activeChannel = channel1;
        channel1.threadsManager.find(channel1.id, this.args.params.threadId).then(thread1 => {
          this.chat.activeChannel.activeThread = thread1;
        });
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
        <navbar.BackButton
          @title={{this.backButton.title}}
          @route={{this.backButton.route}}
          @routeModels={{this.backButton.models}}
        />
        <navbar.Title @title={{this.threadTitle}} @icon="discourse-threads" />
        <navbar.Actions as |a|>
          <a.ToggleDrawerButton />
          <a.FullPageButton />
          <a.CloseDrawerButton />
        </navbar.Actions>
      </Navbar>
  
      {{#if this.chatStateManager.isDrawerExpanded}}
        <div
          class="chat-drawer-content"
          {{didInsert this.fetchChannelAndThread}}
          {{didUpdate this.fetchChannelAndThread @params.channelId}}
          {{didUpdate this.fetchChannelAndThread @params.threadId}}
        >
          {{#each (array this.chat.activeChannel.activeThread) as |thread|}}
            {{#if thread}}
              <ChatThread
                @thread={{thread}}
                @targetMessageId={{@params.messageId}}
              />
            {{/if}}
          {{/each}}
        </div>
      {{/if}}
    
  */
  {
    "id": "em/4ZNaX",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backButton\",\"title\"]],[30,0,[\"backButton\",\"route\"]],[30,0,[\"backButton\",\"models\"]]]],null],[1,\"\\n      \"],[8,[30,1,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[30,0,[\"threadTitle\"]],\"discourse-threads\"]],null],[1,\"\\n      \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[32,1],[[30,0,[\"fetchChannelAndThread\"]]],null],[4,[32,2],[[30,0,[\"fetchChannelAndThread\"]],[30,3,[\"channelId\"]]],null],[4,[32,2],[[30,0,[\"fetchChannelAndThread\"]],[30,3,[\"threadId\"]]],null],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[28,[32,3],[[30,0,[\"chat\",\"activeChannel\",\"activeThread\"]]],null]],null]],null],null,[[[41,[30,4],[[[1,\"            \"],[8,[32,4],null,[[\"@thread\",\"@targetMessageId\"],[[30,4],[30,3,[\"messageId\"]]]],null],[1,\"\\n\"]],[]],null]],[4]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"navbar\",\"a\",\"@params\",\"thread\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-thread.js",
    "scope": () => [_navbar.default, _didInsert.default, _didUpdate.default, _helper.array, _chatThread.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatHistory", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchChannelAndThread", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchChannelAndThread"), _class.prototype)), _class);
});